export class Ticker {
    clones = [];

    constructor(section) {
        this.section = section;
        this.track = this.section.querySelector('[data-ticker-track]');
        this.items = this.section.querySelectorAll('[data-ticker-item]');

        if (this.track && this.items) {
            this.addListeners();
        }
    }

    addListeners() {
        window.addEventListener('load', this.run.bind(this));
        window.addEventListener('resize', this.run.bind(this));
    }

    run () {
        this.reset();
        this.cloneItems();
        this.cloneTrack();

        this.section.dataset.tickerStatus = 'loaded';
    }

    reset () {
        this.clones.forEach(clone => clone.remove());
        this.clones = [];
        this.section.dataset.tickerStatus = 'unloaded';
    }

    cloneTrack() {
        const clone = this.track.cloneNode(true);
        this.section.appendChild(clone);
        this.clones.push(clone);
    }

    cloneItems() {
        const neededCount = this.countNeededClones();
        for (let i = 1; i < neededCount; i++) {
            this.items.forEach((item) => {
                const clone = item.cloneNode(true);
                this.track.appendChild(clone);
                this.clones.push(clone);
            });
        }
    }

    countNeededClones() {
        const width = [...this.items].reduce((acc, currentElem) => acc + parseInt(currentElem.clientWidth), 0);

        return (parseInt(this.track.clientWidth) / width).toFixed(0);
    }
}
