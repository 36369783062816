import Swiper from 'swiper';
import 'swiper/css';
import { Pagination, Navigation  } from 'swiper/modules';

import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default function homeSlider() {
    const swiper = new Swiper('.homeSlider', {
        modules: [Pagination, Navigation],
        slidesPerView: 1,
        lazy: true,
        loop:true,
        pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
        },
        navigation: {
            nextEl: "#next_sl1",
            prevEl: "#prev_sl1",
        },
        on: {
            init: function () {
                updateFraction(this); // Передаємо 'this' як swiper
            },
            slideChange: function () {
                updateFraction(this); // Передаємо 'this' як swiper
            }
        },
    });

    function updateFraction(swiper) {
        let currentSlide = swiper.realIndex + 1;
        let totalSlides = swiper.slides.length;
        document.getElementById('swiper-fraction').innerHTML = '<span>'+currentSlide+'</span>' + '<span>/</span>' + '<span>'+totalSlides+'</span>';
    }
}
