import homeSlider from "../components/home_slider";
import { Ticker } from "../components/ticker";
import productsCarousel from "../components/products-carousel";
import accordion from "../components/faq";
import chooseSliderHome from "../components/choose-slider-home";
import modelsSwiper from "../components/models-swiper";
import bottomCTA from "../components/bottom-cta";

homeSlider();
productsCarousel();
document.querySelectorAll('.ticker').forEach((section) => {
  new Ticker(section);
});

document.addEventListener('DOMContentLoaded', () => {

    accordion();
    //chooseSliderHome();
    modelsSwiper();
    bottomCTA();
});

