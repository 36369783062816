import Swiper from 'swiper';
import 'swiper/css';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default function chooseSliderHome() {

    const allSwipersProducts = document.querySelectorAll('.choose_slider_block-swiper')

    const swiperImg = document.querySelector('.modelSwiper')

    if(swiperImg){
      const swiperImgSlides =  swiperImg.querySelectorAll('.swiper-slide')

      let updateFractionFunc, createBulletsFunc, updateActiveBulletFunc;

      let swipers = []

      const swiperModel = new Swiper(swiperImg, {
          modules: [Navigation],
          spaceBetween: 30,
          allowTouchMove:false,
          slidesPerView: 1,
          navigation: {
              nextEl: ".model-next",
              prevEl: ".model-prev",
          },
          on: {
              // init: function () {
              //   updateFractionFunc(allSwipersProducts[this.activeIndex]); // Передаємо 'this' як swiper
              //   createBulletsFunc(allSwipersProducts[this.activeIndex]);
              // },
              slideChange: function () {
                swipingSlideToFirst(swipers[this.activeIndex]);
                updateFractionFunc(swipers[this.activeIndex]); // Передаємо 'this' як swiper
                createBulletsFunc(swipers[this.activeIndex]);
                updateActiveBulletFunc(swipers[this.activeIndex]);
              }
          }
      });
      const swiperProductModel = new Swiper('.modelProductSwiper', {
          modules: [Navigation],
          spaceBetween: 30,
          slidesPerView: 1,
          allowTouchMove:false,
          navigation: {
              nextEl: ".model-next",
              prevEl: ".model-prev",
          },
      });


      allSwipersProducts.forEach((swiperItem, index) => {

        const productsSwiper =  swiperItem.querySelector('.mySwiper')

        const swiper = new Swiper(productsSwiper, {
          modules: [Pagination, Navigation],
          slidesPerView: 1,
          pagination: {
              el: swiperItem.querySelector(".choose_slider-swiper-pagination"),
              type: "progressbar",
          },
          navigation: {
              nextEl: "#next_sl1",
              prevEl: "#prev_sl1",
          },
          on: {
              init: function () {
                updateFraction(this); // Передаємо 'this' як swiper
                createBullets(this);
              },
              slideChange: function () {
                updateFraction(this); // Передаємо 'this' як swiper
                createBullets(this);
              }
          }
        });

        swipers.push(swiper)


        function updateFraction(swiper) {
            let currentSlide = swiper.realIndex + 1;
            let totalSlides = swiper.slides.length;
            swiperItem.querySelector('.choose_slider-swiper-fraction').innerHTML = '<span>'+currentSlide+'</span>' + '<span>/</span>' + '<span>'+totalSlides+'</span>';
        }

        function createBullets(swiper) {
            const bullets = swiperImgSlides[swiperModel.activeIndex].querySelectorAll('.choose_img-point');
            const chooseSliderBlock = document.querySelector('.choose_slider_block');

            bullets.forEach((bullet, index) => {
                if (index < swiper.slides.length) {
                    bullet.addEventListener('click', () => {
                        swiper.slideTo(index);
                        if (chooseSliderBlock) {
                            chooseSliderBlock.classList.add('active');
                        }
                    });
                } else {
                    bullet.style.display = 'none';
                }
            });

            updateActiveBullet(swiper);

        }

        function updateActiveBullet(swiper) {
            let bullets = swiperImgSlides[swiperModel.activeIndex].querySelectorAll('.choose_img-point');
            bullets.forEach((bullet, index) => {
                bullet.classList.toggle('swiper-pagination-bullet-active', index === swiper.activeIndex);
            });
        }
        const chooseSliderBlock = document.querySelector('.choose_slider_block');

        let chooseClose = document.querySelector('.choose_slider_block-close');
        chooseClose.addEventListener('click', () => {
            chooseSliderBlock.classList.remove('active');
        });

        const wrapper = document.querySelector('.choose_slider_block-wrapper');
        const content = document.querySelector('.choose_slider_block-content');

        wrapper.addEventListener('click', function(event) {
            if (!content.contains(event.target)) {
                chooseSliderBlock.classList.remove('active');
            }
        });

        updateFractionFunc = updateFraction;
        createBulletsFunc = createBullets;
        updateActiveBulletFunc = updateActiveBullet;
      })


      function swipingSlideToFirst(swiper){
        swiper.slideTo(0, 0, false);
      }
    }




}
